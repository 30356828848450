import React from 'react'

const Layout = ({ bgColor, children }) => {
    return (
        <div
            className="layout-container"
            style={bgColor ? { backgroundColor: bgColor } : null}
        >
            <div className="layout-box">{children}</div>
        </div>
    )
}

export default Layout
