import { BsGeoAltFill, BsEnvelopeFill, BsTelephoneFill } from 'react-icons/bs'
export const footerData = [
    {
        id: 1,
        title: 'VISIT US',
        icon: <BsGeoAltFill className="footer-icon-style" />,
        detail: '199 Moo 9, Lumtasao, Wangnoi, Ayutthaya, 13170 Thailand',
    },
    {
        id: 2,
        title: 'MESSAGE US',
        icon: <BsEnvelopeFill className="footer-icon-style" />,
        detail: 'cynthia.zhang@unicabinetgroup.com',
    },
    {
        id: 3,
        title: 'CALL US',
        icon: <BsTelephoneFill className="footer-icon-style" />,
        detail: '(00)66 988603781',
    },
]
