import React from 'react'
import Layout from '../components/Layout'

const History = () => {
    return (
        <Layout>
            <div id="History" className="history-container">
                <h2 className="history-title">OUR HISTORY</h2>
                <div className="history-pic-box">
                    <img
                        src="https://res.cloudinary.com/homespace/image/upload/v1712617343/logo/WechatIMG764_hcvzwp.webp"
                        alt="history"
                        className="history-pic"
                    />
                </div>
            </div>
        </Layout>
    )
}

export default History
