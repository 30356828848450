import React, { useState } from 'react'
import Layout from '../components/Layout'
import { knowMoreData, singleKnowMoreData } from '../config/knowMoreData'

const KnowMore = () => {
    const [knowDetail, setKnowDetail] = useState(singleKnowMoreData)
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        if (open) {
            setKnowDetail(singleKnowMoreData)
            setOpen(false)
        } else {
            setKnowDetail(knowMoreData)
            setOpen(true)
        }
    }

    return (
        <Layout>
            <div className="knowmore-container">
                <div className="knowmore-header">KNOW MORE</div>
                <div className="knowmore-select-box">
                    <div className="knowmore-text">
                        <div className="knowmore-text-word">
                            {knowDetail.map((item, index) => (
                                <div key={index} className="knowmore-detail">
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="knowmore-icon">
                        <span
                            className={`down-arrow ${open ? 'active' : null}`}
                            onClick={() => handleOpen()}
                        ></span>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default KnowMore
