export const videoData = [
    {
        id: 1,
        title: 'Optimize cutting',
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657991837/web/videos/Screen_Shot_2022-07-16_at_1.16.14_PM_kxxe7d.png',
        video: 'https://res.cloudinary.com/dcmxioazd/video/upload/v1657700062/web/videos/Video1-Optimize_Cutting_x6koke.mp4',
    },
    {
        id: 2,
        title: 'Milling and Drilling',
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657991837/web/videos/Screen_Shot_2022-07-16_at_1.16.23_PM_oribks.png',
        video: 'https://res.cloudinary.com/dcmxioazd/video/upload/v1657700070/web/videos/Video2-Milling_and_Drilling_jdaivu.mp4',
    },
    {
        id: 3,
        title: 'Primer coating',
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657991837/web/videos/Screen_Shot_2022-07-16_at_1.16.35_PM_d7g9wp.png',
        video: 'https://res.cloudinary.com/dcmxioazd/video/upload/v1657700062/web/videos/Video3-Primer_Coating_iwfdqo.mp4',
    },
    {
        id: 4,
        title: 'Top coating',
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657991837/web/videos/Screen_Shot_2022-07-16_at_1.16.47_PM_qmnpn4.png',
        video: 'https://res.cloudinary.com/dcmxioazd/video/upload/v1657700062/web/videos/Video4-Top_Coating_lavicp.mp4',
    },
    {
        id: 5,
        title: 'Packaging',
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657991837/web/videos/Screen_Shot_2022-07-16_at_1.17.00_PM_mcadye.png',
        video: 'https://res.cloudinary.com/dcmxioazd/video/upload/v1657700063/web/videos/Video5-Packaging_zdpeyp.mp4',
    },
]
