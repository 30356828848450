export const knowMoreData = [
    'Company Core Competency',
    ' - Rich experiences in solid wood product and wrapping product since year 1993',
    ' - Experiences in the frame including the cabinet door since year 2000',
    ' - Lean production',
    ' - High quality ,low cost',
    ' - Diversity of Material',
    ' - Automation production line',
    ' - Own solid wood manufacture in Russia',
    ' - Free Capacity',
    ' - Located in Thailand',
]

export const singleKnowMoreData = ['Company Core Competency']
