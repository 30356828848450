import React from 'react'
import { Link } from 'react-scroll'
import { headerData } from '../config/headerData'

import DrawerToggle from '../sideDrawer/DrawerToggle'

const NavB = ({ drawerClickHandler }) => {
    window.addEventListener('scroll', function () {
        const header = document.querySelector('header')
        header.classList.toggle('active', window.scrollY > 0)
    })
    return (
        <header className="toolbar">
            <div className="toolbar-container">
                <nav className="toolbar__navigation">
                    <div className="toolbar__toggle-button">
                        <DrawerToggle click={drawerClickHandler} />
                    </div>
                    <div className="toolbar__logo">
                        <Link
                            to="Top"
                            smooth={true}
                            spy={true}
                            offset={-70}
                            duration={500}
                            style={{ cursor: 'pointer' }}
                        >
                            <img
                                src="https://res.cloudinary.com/dcmxioazd/image/upload/v1657701476/web/logo1_qrexje.svg"
                                alt="logo"
                                width="50px"
                                height="50px"
                            />
                        </Link>
                    </div>
                    {/* <div className="spacer"></div> */}
                    <div className="toolbar_navigation-items">
                        <ul>
                            {headerData.map((item) => (
                                <li key={item.id}>
                                    <Link
                                        to={item.location}
                                        smooth={true}
                                        spy={true}
                                        offset={-70}
                                        duration={500}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {item.title}
                                    </Link>
                                </li>
                            ))}

                            <li>
                                <Link
                                    to="GetInTouch"
                                    smooth={true}
                                    spy={true}
                                    offset={-70}
                                    duration={500}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <button className="toolbar-header-button">
                                        GET IN TOUCH
                                    </button>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default NavB
