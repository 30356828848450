import React from 'react'
import Layout from '../components/Layout'
import { certificationsData } from '../config/certificationsData'

const Certifications = () => {
    return (
        <Layout>
            <section id="Certifications" className="cer-container">
                <h3 className="cer-header">CERTIFICATIONS</h3>
                <div className="cer-box">
                    {certificationsData.map((item) => (
                        <div key={item.id} className="cer-item-box">
                            <img
                                src={item.pic}
                                alt="cer-logo"
                                className="cer-pic"
                            />
                            <div className="cer-title">{item.title}</div>
                            <a href={item.pdf} target="_blank">
                                <button className="cet-button">
                                    CERTIFICATE
                                </button>
                            </a>
                        </div>
                    ))}
                </div>
            </section>
        </Layout>
    )
}

export default Certifications
