import React from 'react'
import { Link } from 'react-scroll'
import { headerData } from '../config/headerData'

const SideDrawer = (props) => {
    let drawerClasses = ['side-drawer']
    if (props.show) drawerClasses = ['side-drawer open']
    return (
        <nav className={drawerClasses}>
            <ul>
                {headerData.map((item) => (
                    <li key={item.id}>
                        <Link
                            to={item.location}
                            smooth={true}
                            spy={true}
                            offset={-70}
                            duration={1000}
                            style={{ cursor: 'pointer' }}
                            onClick={props.click}
                        >
                            {item.title}
                        </Link>
                    </li>
                ))}

                <li>
                    <Link
                        to="GetInTouch"
                        smooth={true}
                        spy={true}
                        offset={-70}
                        duration={500}
                        style={{ cursor: 'pointer' }}
                        onClick={props.click}
                    >
                        <button className="toolbar-header-button">
                            GET IN TOUCH
                        </button>
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default SideDrawer
