import React from 'react'
import ReactPlayer from 'react-player'

const Player = ({ video }) => {
    return (
        <div id="VideoPlayer" className="video-player-container">
            <ReactPlayer
                controls
                url={video}
                className="video-player-video"
                height="500px"
            />
        </div>
    )
}

export default Player
