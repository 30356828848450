import React from 'react'
import { Link } from 'react-scroll'

const Home = () => {
    return (
        <>
            <section className="section-one" id="Top">
                <div className="home-container">
                    <img
                        src="https://res.cloudinary.com/dcmxioazd/image/upload/v1657701476/web/logo2_xg44zy.svg"
                        alt="main"
                        className="home-logo"
                    />
                </div>
                <div className="home-banner"></div>
                <div className="home-icon">
                    <Link
                        to="Certifications"
                        smooth={true}
                        spy={true}
                        offset={-70}
                        duration={500}
                        style={{ cursor: 'pointer' }}
                    >
                        <span className="home-span"></span>
                    </Link>
                </div>
            </section>
        </>
    )
}

export default Home
