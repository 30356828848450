import React, { useState } from 'react'
import Backdrop from '../backDrop/Backdrop'
import Layout from '../components/Layout'
import Player from '../components/Player'
import { videoData } from '../config/videosData'

const Videos = () => {
    const [show, setShow] = useState(false)
    const [videoUrl, setVideoUrl] = useState(null)
    return (
        <Layout bgColor="#e29d20">
            {show && <Backdrop click={() => setShow(false)} />}

            <div id="Videos" className="videos-container">
                {show && <Player video={videoUrl} />}
                <h2 className="videos-title">PRODUCTION PROCESS</h2>
                <div className="videos-box">
                    {videoData.map((video) => (
                        <div key={video.id} className="video-item-box">
                            <img
                                src={video.pic}
                                alt="video"
                                className="video-pic"
                                onClick={() => {
                                    setShow(true)
                                    setVideoUrl(video.video)
                                }}
                            />
                            <h2 className="video-count">0{video.id}</h2>
                            <div className="video-title">{video.title}</div>
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}

export default Videos
