export const certificationsData = [
    {
        id: 1,
        title: 'Environmental Management System',
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657700866/web/certificates/EMS_lkdwl1.svg',
        pdf: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657700461/web/certificates/EMS_certificates_psm7yy.png',
    },
    {
        id: 2,
        title: 'Quality Management System',
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657701052/web/certificates/OMS_yprs7a.svg',
        pdf: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657700461/web/certificates/QMS_certificates_zxfwpl.png',
    },
    {
        id: 3,
        title: 'Occupational health and safety Management System',
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657701052/web/certificates/OHMSM_odhu1z.svg',
        pdf: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657700460/web/certificates/OHSMS_certificates_xmols4.png',
    },
    // {
    //     id: 4,
    //     title: 'The Mark of Responsible Forestry',
    //     pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657701052/web/certificates/MRF_mn8jlo.svg',
    //     pdf: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657700460/web/certificates/COC_certificates_moijp8.png',
    // },
]
