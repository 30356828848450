export const factoryData = [
    {
        id: 1,
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657763986/web/Our%20Factory-Pictures/1_opbceh.webp',
    },
    {
        id: 2,
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657763984/web/Our%20Factory-Pictures/15_hggtgn.webp',
    },
    {
        id: 3,
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657763984/web/Our%20Factory-Pictures/7_zo2mks.webp',
    },
    {
        id: 4,
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657763982/web/Our%20Factory-Pictures/13_ypowze.webp',
    },
    {
        id: 5,
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657763981/web/Our%20Factory-Pictures/10_tvzizr.webp',
    },
    {
        id: 6,
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657763979/web/Our%20Factory-Pictures/3_ui1wdy.webp',
    },
    {
        id: 7,
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657763978/web/Our%20Factory-Pictures/11_m2aiku.webp',
    },
    {
        id: 8,
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657763978/web/Our%20Factory-Pictures/14_zfoqza.webp',
    },
    {
        id: 9,
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657763977/web/Our%20Factory-Pictures/6_jorqc9.webp',
    },
    {
        id: 10,
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657763976/web/Our%20Factory-Pictures/2_sckaos.webp',
    },
    {
        id: 11,
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657763974/web/Our%20Factory-Pictures/8_pn9rrl.webp',
    },
    {
        id: 12,
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657763973/web/Our%20Factory-Pictures/4_xcxdco.webp',
    },
    {
        id: 13,
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657763971/web/Our%20Factory-Pictures/5_xpvvtz.webp',
    },
    {
        id: 14,
        pic: 'https://res.cloudinary.com/dcmxioazd/image/upload/v1657763966/web/Our%20Factory-Pictures/9_w0xx5j.webp',
    },
]
