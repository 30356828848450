export const aboutUsData = [
    { id: 1, title: 'Company Name: Unicabinet Industry Co.,Ltd', subTitle: [] },
    {
        id: 2,
        title: 'Location: Ayutthaya, Thailand',
        subTitle: [
            { id: 101, item: '90kms to Bangkok airport' },
            { id: 102, item: '90kms to port Lat Krabang' },
        ],
    },
    {
        id: 3,
        title: 'Workshop area: 300,000 sq. ft',
        subTitle: [
            { id: 201, item: 'Another 300,000 sq. ft for the next phase' },
        ],
    },
    { id: 4, title: "Equipment's investment: US$ 20 Millions", subTitle: [] },
    { id: 5, title: 'Main product: Kitchen & Bathroom cabinets', subTitle: [] },
    {
        id: 6,
        title: 'Designed capacity: Full cabinet',
        subTitle: [{ id: 201, item: '180-200containers per month' }],
    },
    { id: 7, title: 'Mass Production Since: February 2020', subTitle: [] },
    { id: 8, title: 'Current Customers: KCD, AWC;', subTitle: [] },
]
