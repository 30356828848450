import React, { useState } from 'react'
import Layout from '../components/Layout'
import { factoryData } from '../config/factoryData'
import { formatPic } from '../utils/formatPic'

const Factory = () => {
    const [displayPic, setDisplayPic] = useState(() => factoryData[0])
    return (
        <Layout bgColor="#424c65">
            <div id="Factory" className="factory-container">
                <div className="factory-header">OUR FACTORY</div>
                <div className="factory-pic-container">
                    <div className="factory-big-pic">
                        <img
                            src={formatPic(displayPic.pic, 1000)}
                            alt="big"
                            className="factory-big-pic-pic"
                        />
                    </div>
                    <div className="factory-small-pic">
                        {factoryData.map((pic) => (
                            <div key={pic.id} className="factory-pic-item">
                                <img
                                    src={formatPic(pic.pic, 180)}
                                    alt="small"
                                    className={`factory-pic-pic ${
                                        displayPic.id === pic.id
                                            ? 'factory-pic-item-active'
                                            : ''
                                    }`}
                                    onClick={() => setDisplayPic(pic)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Factory
