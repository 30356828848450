import React from 'react'
import Layout from '../components/Layout'
import { footerData } from '../config/footerData'

const Footer = () => {
    return (
        <Layout>
            <div className="footer-container">
                <div className="footer-top-box">
                    {footerData.map((item) => (
                        <div key={item.id} className="footer-item">
                            <div className="footer-item-title">
                                {item.title}
                            </div>
                            <div className="footer-item-box">
                                <div className="footer-item-icon">
                                    {item.icon}
                                </div>
                                <div className="footer-item-detail">
                                    {item.detail}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">
                    <div className="footer-bottom-left">
                        UniCabinet © Copyright {new Date().getFullYear()}
                    </div>
                    <div className="footer-bottom-center">UNICABINET</div>
                    <div className="footer-bottom-right">
                        <div className="footer-pp">Privacy Policy</div>
                        <div className="footer-line"></div>
                        <div className="footer-ts">Terms of Service</div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Footer
