import React from 'react'
import Layout from '../components/Layout'
import { aboutUsData } from '../config/aboutUsData'

const AboutUsItem = ({ item }) => {
    return (
        <div className="aboutusitem-container">
            <div className="aboutusitem-box">
                <div className="aboutusitem-icon">
                    <img
                        src="https://res.cloudinary.com/dcmxioazd/image/upload/v1657701476/web/verify_uitggm.svg"
                        alt="icon"
                        className="aboutusitem-icon-icon"
                    />
                </div>
                <div className="aboutusitem-text-box">
                    <div className="aboutusitem-title">{item.title}</div>
                    <div className="aboutusitem-sub">
                        {item.subTitle.map((sub) => (
                            <div key={sub.id} className="aboutusitem-sub-text">
                                {sub.item}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

const AboutUs = () => {
    return (
        <Layout>
            <section id="AboutUs" className="aboutus-container">
                <div className="aboutus-pic">
                    <img
                        src="https://res.cloudinary.com/dcmxioazd/image/upload/v1657985840/web/1_mmsesz_bl4pb8.webp"
                        alt="pic1"
                        className="aboutus-pic1"
                    />
                    <img
                        src="https://res.cloudinary.com/dcmxioazd/image/upload/v1657700478/web/2_sp6key.webp"
                        alt="pic2"
                        className="aboutus-pic2"
                    />
                </div>
                <img
                    src="https://res.cloudinary.com/dcmxioazd/image/upload/v1657701477/web/logo3_u39muo.svg"
                    alt="logo"
                    className="aboutus-logo"
                />
                <h2 className="aboutus-title">ABOUT US</h2>
                <div className="aboutus-item-box">
                    <div className="aboutus-item-text">
                        <div className="aboutus-item-ul">
                            {aboutUsData.slice(0, 4).map((item) => (
                                <AboutUsItem key={item.id} item={item} />
                            ))}
                        </div>
                    </div>
                    <div className="aboutus-item-line"></div>
                    <div className="aboutus-item-text">
                        <div className="aboutus-item-ul">
                            {aboutUsData.slice(4).map((item) => (
                                <AboutUsItem key={item.id} item={item} />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default AboutUs
