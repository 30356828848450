import React, { useState } from 'react'
import Backdrop from './backDrop/Backdrop'
import Home from './pages/Home'
import NavB from './components/Nav'
import SideDrawer from './sideDrawer/SideDrawer'
import AboutUs from './pages/AboutUs'
import Certifications from './pages/Certifications'
import Videos from './pages/Videos'
import History from './pages/History'
import Factory from './pages/Factory'
import KnowMore from './pages/KnowMore'
import GetInTouch from './pages/GetInTouch'
import Footer from './pages/Footer'

function App() {
    const [sideDrawerOpen, setSideDrawerOpen] = useState(false)

    const drawerToggleClickHandler = () => {
        setSideDrawerOpen(!sideDrawerOpen)
    }

    const backdropClickHandler = () => {
        setSideDrawerOpen(false)
    }
    return (
        <div>
            <NavB drawerClickHandler={drawerToggleClickHandler} />
            <SideDrawer show={sideDrawerOpen} click={backdropClickHandler} />
            {sideDrawerOpen && <Backdrop click={backdropClickHandler} />}
            <Home />
            <Certifications />
            <AboutUs />
            <Videos />
            <History />
            <Factory />
            <KnowMore />
            <GetInTouch />
            <Footer />
        </div>
    )
}

export default App
