import React from 'react'
import Layout from '../components/Layout'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import emailjs from '@emailjs/browser'

const getInTouchSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string().min(6).max(12).required('Required'),
    message: Yup.string().max(300),
})

const GetInTouch = () => {
    return (
        <Layout>
            <div id="GetInTouch" className="touch-container">
                <div className="touch-left">
                    <div className="touch-header">GET IN TOUCH</div>

                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            phone: '',
                            message: '',
                        }}
                        validationSchema={getInTouchSchema}
                        onSubmit={(values, { resetForm }) => {
                            emailjs
                                .send(
                                    'service_zl555at',
                                    'template_wgdek25',
                                    values,
                                    'nz2bY5jm9LzfRm7T1'
                                )
                                .then(
                                    (result) => {
                                        console.log(result.text)
                                    },
                                    (error) => {
                                        console.log(error)
                                    }
                                )
                            window.alert('Thanks for your information')
                            resetForm()
                        }}
                    >
                        {({ errors, touched, isValid }) => (
                            <Form>
                                <div className="touch-input-box">
                                    <div className="touch-input-item">
                                        <Field
                                            name="name"
                                            className="touch-input-style"
                                            placeholder="NAME"
                                        />
                                        <Field
                                            name="email"
                                            className="touch-input-style"
                                            placeholder="EMAIL"
                                        />
                                        <Field
                                            name="phone"
                                            className="touch-input-style"
                                            placeholder="PHONE"
                                        />
                                    </div>
                                    <div className="touch-input-item">
                                        <Field
                                            name="message"
                                            as="textarea"
                                            className="touch-textarea-style"
                                            placeholder="MESSAGE"
                                        />
                                        <button
                                            type="submit"
                                            className="touch-button"
                                            disabled={!isValid}
                                        >
                                            SUBMIT
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="touch-right"></div>
            </div>
        </Layout>
    )
}

export default GetInTouch
